*{
    /* outline: solid 1px chartreuse; */
    font-family: 'Ubuntu', sans-serif;
    padding: 0;
    margin: 0;
}

#app{
    background-color: #0d1117;
}

#app main{
    display: inline-block;
    margin-top: 75px;
    min-height: 200vh;
    width: 100%;
}

/* Beginning of Navbar styling */

#app header{
    position: fixed;
    width: 100%;
    z-index: 1;

}

#app nav{
    background-color: #0d1117;
    height: 75px;
    text-align: center;
    width: 100%;
}

#app #shadow{
    box-shadow: 0px 0px 20px 15px #212121;
    animation-duration: 2s;
    animation-name: navShadow;
}

@keyframes navShadow{
    from{
        box-shadow: 0px 0px 0px 0px #212121;
    }
    to{
        box-shadow: 0px 0px 20px 15px #212121;
    }
}

#app nav div{
    display: inline-block;
    width: 85%;
    text-align: left;
}

#app nav div span{
    display: inline-block;
    height: 0px;
    margin: 20px 50px 0 0;
}
#app nav div img{
    height: 70px;
    vertical-align: top;
}

#app nav div .social{
    color: #fffff0;
    display: inline-block;
    font-size: 30pt;
    margin-top: 20px;
    vertical-align: top;
}

#app nav div .social:nth-of-type(1){
    color: #0077b5;
    margin-left: 25px;
    margin-right: 10px;
}

#app nav div ul, #app nav div ul li{
    display: inline-block;
    list-style: none;
    vertical-align: top;
}

#app nav div ul{
    position: absolute;
    right: 25px;
    padding: 0;
    text-align: center;
}
#app nav div ul li{
    font-size: 18pt;
    padding: 25px 0;
    margin: 0 25px;
}

#app nav div .noHover{
    animation-duration: 500ms;
    animation-name: fadeOut;
    opacity: .25;
}

@keyframes fadeOut{
    from{
        opacity: 1;
    }
    to{
        opacity: 0.25;
    }   
}

#app nav a{
    color: #fffff0;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}
#app nav a:hover{
    cursor: pointer;
}

/* End of Navbar Styling */


/* Beginning of About Styling */

#app #about, #app #projects{
    padding: 75px 0px;
    text-align: center; 
    width: 100%;
}

#app #about div{
    padding: 0;
    margin: 0 10vw 0 0;
    display: inline-block;
    width: 600px;
    text-align: center;
}

#app #about div h1{
    font-size: 50pt;
    color: #0077b5;
    padding: 0;
    margin: 0 0 30px 0;
    text-align: left;
}

#app #about div p{
    color: #fffff0;
    font-size: 20pt;
    margin: 20px 0;
    padding: 0;
    text-align: left;
}

#app #about div p span{
    font-weight: bold;
}

#app #about img{
    display: inline-block;
    background-color: #0077b5;
    border-radius: 50%;
    padding: 0;
    margin: 50px 0 0 0;
    width: 350px;
    vertical-align: top;
}

/* End of About Styling */

/* Beginning of Projects Styling */

#app #projects{
    margin: 150px 0 200px 0;
}

#app #projects h1{
    color: #0077b5;
    margin: 0 0 50px 0;
    font-size: 50pt;
}

#app #projectCard{
    background-color: #00273d;
    color: #fffff0;
    display: inline-block;
    margin: 30px 60px 75px 60px;
    padding: 20px 10px;
    width: 450px;
    vertical-align: top;
}

#app #projectCard:hover{
    cursor: pointer;
}

#app #projectCard:hover img{
    transform: scale(1.15);
    filter: grayscale(0);
}

#app #projectCard .detailsIcon{
    margin: 0 0 0 10px;
    transition: all 200ms ease-in-out;
}

#app #projectCard:hover .detailsIcon{
    color: #0077b5;
    transform: scale(1.5);
}

#app #projectCard:hover div p:last-of-type{
    color: #0077b5;
    text-decoration: underline;
}

#app #projectCard img{
    filter: grayscale(1);
    display: inline-block;
    max-height: 200px;
    position: relative;
    bottom: 30px;
    width: 90%;
    transition: all 200ms ease-in-out;
}

#app #projectCard h3{
    bottom: 15px;
    font-size: 25pt;
    display: inline-block;
    position: relative;
}

#app #projectCard div p{
    display: inline-block;
    margin: 15px 0 0 0;
    min-height: 0;
    padding: 0;
    width: 60%;
}

#app #projectCard div p:last-of-type{
    text-align: right;
    margin: 15px 0 0 0;
    width: 35%;
}

#app #projectCard p{
    font-size: 15pt;
    min-height: 125px;
    padding: 0px 15px;
    text-align: left;
}

#app #projectCard div .techIcon{
    font-size: 25pt;
    margin: 0 5px;
}

#app #projectCard:hover div #javascript{
    color: #efd81d;
}
#app #projectCard:hover div #react{
    color: #61DCFB;
}
#app #projectCard:hover div #node{
    color: #83CD29;
}
#app #projectCard:hover div #mongodb{
    color: #3F9D39;
}
#app #projectCard:hover div #python{
    color: #F7BD30;
}
#app #projectCard:hover div #html{
    color: #E34F26;
}
#app #projectCard:hover div #css{
    color: #006FB9;
}
#app #projectCard:hover div #django{
    color: #27AB77;
}
#app #projectCard:hover div #sqlite{
    color: #8ED3F3;
}
#app #projectCard:hover div #csharp{
    color: #189F20;
}
#app #projectCard:hover div #bootstrap{
    color: #563D7C;
}
#app #projectCard:hover div #dotnet{
    color: #006FB2;
}
#app #projectCard:hover div #mysql{
    color: #E48E00;
}

@keyframes fadeIn{
    from{
    opacity: 0;
    left: -100%;
    }
    to{
    opacity: 1;
    left: 0;
    }
}
#app .projectDetails{
    animation: fadeIn 500ms;
    display: inline-block;
    background-color: #0d1117;
    height: 100%;
    left: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    width: 100%;
    text-align: center;
    z-index: 1;
}
#app .projectDetails::-webkit-scrollbar{
    display: none;
}

#app .projectDetails #closeDetails{
    color: #fffff0;
    animation: fadeIn 500ms;
    font-size: 35pt;
    left: 1%;
    position: fixed;
    top: 1%;
    transition: all 200ms ease-in-out;
    z-index: 1;
}
#app .projectDetails #closeDetails:hover{
    transform: scale(1.2);
    cursor: pointer;
}

#app .projectDetails #carouselContainer{
    text-align: center;
}

#app .projectDetails #carouselContainer img{
    box-shadow: none;
}

#app .projectDetails img{
    display: inline-block;
    box-shadow: #383838 5px 5px 25px 5px;
    margin: 20px 0 0 0;
    max-height: 60%
}

#app .projectDetails #projectTitle{
    font-size: 25pt;
    margin: 20px 0;
}

#app .projectDetails p{
    color: #fffff0;
    display: inline-block;
    font-size: 15pt;
    margin-bottom: 50px;
    line-height: 125%;
    text-align: left;
    width: 75%;
}

#app .projectDetails #projectDetailsLinks{
    font-size: 30pt;
    margin: 20px 0;
}
#app .projectDetails #projectDetailsLinks a{
    color: #fffff0;
    margin: 0 1.5%;
    text-decoration: none;
}

#app .projectDetails #projectDetailsLinks #playDemo, #app .projectDetails #projectDetailsLinks #detailsGithub{
    margin: 0 10px 0 0;
    position: relative;
    top: 5px;
}
#app .projectDetails #projectDetailsLinks #playDemoLink:hover #playDemo{
    color: #189F20;
}
#app .projectDetails #projectDetailsLinks #githubLink:hover #detailsGithub{
    color: #189F20;
}
/* End of Projects Styling */
/* Beginning of Education section styling */

#app #education{
    text-align: center;
    margin: 0 0 200px 0;
}

#app #education h1{
    display: inline-block;
    color: #0077b5;
    font-size: 50pt;
    overflow: visible;
    padding: 0;
    margin: 0 0 30px 0;
    text-align: center;
    width: 100%;
}
#app #education #schoolToggle{
    border: solid #000 1px;
    border-radius: 25px;
    display: inline-block;
    height: 50px;
    margin: 0 35% 25px 35%;
    width: 400px;
}
#app #education #schoolToggle p{
    display: inline-block;
    font-size: 15pt;
    padding: 12px 0 0 0;
    height: 38px;
    text-align: center;
    width: 50%;
}
#app #education #schoolToggle p:nth-of-type(1){
    border-radius: 25px 0 0 25px;
}
#app #education #schoolToggle p:nth-of-type(2){
    border-radius: 0 25px 25px 0;
}
@keyframes colorFill{
    from{background-color: #fff;}
    to{background-color: rgba(160,160,160,.5);}
}
@keyframes colorEmpty{
    from{background-color: rgba(160,160,160,.5);}
    to{background-color: #fff;}
}
#app #education .schoolTypeActive{
    animation: colorEmpty 500ms;
    background-color: #fff;
    font-weight: bold;
}
#app #education .schoolTypeActive:hover{cursor: pointer;}
#app #education .schoolTypeInactive:hover{cursor: pointer;}
#app #education #schoolToggle .schoolTypeInactive{
    animation: colorFill 500ms;
    background-color: rgba(160,160,160,.5);
}

@keyframes cardFade{
    from{opacity: 0;}
    to{opacity: 1;}
}

#app #education .schoolCard{
    animation: cardFade 1000ms;
    box-shadow: #808080 0px 0 20px 1px;
    color: #fffff0;
    cursor: pointer;
    display: inline-block;
    height: 400px;
    margin: 15px 2.5%;
    width: 350px;
    vertical-align: top;
}
#app #education .schoolCard div:nth-of-type(1){
    height: 400px;
}
#app #education .schoolCard img{
    height: 250px;
}
#app #education .schoolCard h1{
    display: inline-block;
    font-size: 15pt;
    margin: 0;
    width: 95%;
}
#app #education .schoolCard p:nth-of-type(1){
    color: #636363;
    margin: 10px 0 15px 0;
}
@keyframes menuIn{
    from{
    background-color: rgba(44, 56, 74, 0);
    backdrop-filter: blur(0px);
    opacity: 0;
    }
    to{
    background-color: rgba(44, 56, 74, .75);
    backdrop-filter: blur(5px);
    opacity: 1;
    }
}
#app #education .menuActive{
    animation: menuIn 1000ms;
    background-color: rgba(44, 56, 74, .75);
    backdrop-filter: blur(5px);
    bottom: 400px;
    height: 300px;
    padding: 50px 0;
    position: relative;
    width: 100%;
}
#app #education .menuActive li, #app #education .menuActive a{
    display: inline-block;
    margin: 5px 0;
    width: 75%;
}
#app #education .menuHidden{
    height: 0px;
    opacity: 0;
    overflow: hidden;
}
#app #education .menuActive a{
    color: #75fdff;
}
#app #education .menuActive a:visited{
    color: #da75ff;
}

  /* End of education section styling */

/* Beginning of Contact section */

#app #contact {
    text-align: center;
    margin: 0 0 150px 0;
}

#app #contact h1{
    color: #0077b5;
    font-size: 50pt;
    margin: 0 0 50px 0;
}

#app #contact a{
    display: inline-block;
    border-radius: 10px;
    background-color: #0077b5;
    color: #fff;
    font-size: 20pt;
    margin: 0px 10px;
    padding: 5px 10px;
    text-decoration: none;
    transition: all 200ms ease-in-out;
}
#app #contact a:hover{
    transform: scale(1.2);
}
#app #contact a span{
    display: inline-block;
    padding-top: 10px;
    vertical-align: top;
}

#app #contact a .contactIcons{
    margin-top: 5px;
    font-size: 30pt;
}

#app #contact #contactForm{
    box-shadow: #525252 0px 0px 20px 1px;
    border-radius: 50px;
    display: inline-block;
    margin: 50px 0 0 0;
    padding: 50px 50px 0px 50px;
    width: 70%;
}
#app #contact #contactForm input{
    background-color: #2c323b;
    border: none;
    color: #fffff0;
    display: inline-block;
    height: 50px;
    font-size: 15pt;
    margin: 0 30px;
    padding: 0 15px;
    width: 35%;
}
#app #contact #contactForm input::placeholder{
    color: #fffff0;
}
#app #contact #contactForm textarea{
    background-color: #2c323b;
    border: none;
    color: #fffff0;
    font-size: 15pt;
    margin: 35px 0 0 0;
    min-height: 200px;
    padding: 25px;
    width: 75%;
}
#app #contact #contactForm textarea::placeholder{
    color: #fffff0;
}
#app #contact #contactForm button{
    background-color: #00b51b;
    border: none;
    border-radius: 20px;
    display: inline-block;
    font-size: 20pt;
    height: 50px;
    margin: 30px 30%;
    width: 150px;
    transition: all ease-in-out 200ms;
}
#app #contact #contactForm button:hover{
    transform: scale(1.2);
}
#app #contact #contactForm button #formIcon{
    display: inline-block;
    position: relative;
    right: 10px;
    top: 2px;
}
/* End of Contact section */

/* ----------------------------------------- */
/* ----------------------------------------- */
/* Beginning of Mobile Styling */
/* ----------------------------------------- */
/* ----------------------------------------- */

/* Beginning of mobile navbar */

#app nav #mobileNavDiv span{
    margin: 20px 10px 0 0;
}

#app nav div #mobileBars{
    color: #0077b5;
    font-size: 50pt;
    position: absolute;
    right: 15px;
    top: 5px;
    vertical-align: top;
}
#app #showNav #closeNav{
    color: #0077b5;
    padding: 20px 0;
    text-align: center;
    width: 100%;
}

@keyframes displayNav{
    from{
        left: -100%;
    }
    to{
        left: 0;
    }
}

#app nav div #showNav{
    animation: displayNav 250ms ease-in-out;
    background-color: rgba(13, 17, 23, .95);
    display: inline-block;
    font-size: 30pt;
    height: 100vh;
    left: 0;
    padding: 75px 0;
    position: absolute;
    top: 0;
    width: 100%;
}

#app nav div #showNav a{
    width: 100%;
    padding: 20px 0;
    text-align: center;
}

#app nav div #hideNav{
    display: none;
}

  /* End of mobile navbar */


/* Beginning of mobile About section */

#app #mobileAbout{
    text-align: center;
    width: 100%;
}

#app #mobileAbout img{
    background-color: #0077b5;
    border-radius: 50%;
    margin: 50px 0 0 0;
    width: 325px;
}

#app #mobileAbout div{
    padding: 10vw;
    text-align: left;
}

#app #mobileAbout div h1{
    color: #0077b5;
    font-size: 30pt;
    margin-bottom: 25px;
}

#app #mobileAbout div p{
    color: #fffff0;
    margin: 20px 0;
}

@media screen and (max-width: 500px){
    #app #mobileAbout div p{
        font-size: 18pt;
    }
}

@media screen and (min-width:501px) and (max-width: 1200px){
    #app #mobileAbout div p{
        font-size: 25pt;
    }
    #app #mobileAbout div h1{
        font-size: 50pt;
    }
}

#app #mobileAbout div p span{
    font-weight: bold;
}

/* End of mobile About section */

/* Beginning of mobile Projects section */

@media screen and (max-width: 500px){
    #app #mobileProjects h1{
        font-size: 30pt !important;
    }
}

#app #mobileProjects{
    margin: 150px 0 200px 0;
    text-align: center;
}

#app #mobileProjects h1{
    color: #0077b5;
    margin: 0 0 50px 0;
    font-size: 50pt;
}

@media only screen and (max-width: 600px){
    #app #mobileProjects #projectCard{
    margin: 30px 0 75px 0;
    width: 80%;
    }

    #app #mobileProjects #projectCard div p{
    width: 90%;
    }

    #app #mobileProjects #projectCard div p:last-of-type{
    width: 10%;
    }
}

#app #projectCard #mobileCardIcons #javascriptActive{
    color: #efd81d;
}
#app #projectCard #mobileCardIcons #reactActive{
    color: #61DCFB;
}
#app #projectCard #mobileCardIcons #nodeActive{
    color: #83CD29;
}
#app #projectCard #mobileCardIcons #mongodbActive{
    color: #3F9D39;
}
#app #projectCard #mobileCardIcons #pythonActive{
    color: #F7BD30;
}
#app #projectCard #mobileCardIcons #htmlActive{
    color: #E34F26;
}
#app #projectCard #mobileCardIcons #cssActive{
    color: #006FB9;
}
#app #projectCard #mobileCardIcons #djangoActive{
    color: #27AB77;
}
#app #projectCard #mobileCardIcons #sqliteActive{
    color: #8ED3F3;
}
#app #projectCard #mobileCardIcons #csharpActive{
    color: #189F20;
}
#app #projectCard #mobileCardIcons #bootstrapActive{
    color: #563D7C;
}
#app #projectCard #mobileCardIcons #dotnetActive{
    color: #006FB2;
}
#app #projectCard #mobileCardIcons #mysqlActive{
    color: #E48E00;
}

#app #projectCard .mobileDetailsIcon{
    margin: 0 0 0 10px;
    transition: all 200ms ease-in-out;
}

#app #projectCard .mobileDetailsIconActive{
    color: #0077b5;
    transform: scale(1.5);
    transition: all 200ms ease-in-out;
}

#app #projectCard #cardImageActive{
    transform: scale(1.15);
    filter: grayscale(0);
    transition: all 200ms ease-in-out;
}

#app #mobileProjectDetails{
    padding: 0;
}

#app #mobileProjectDetails #projectDetailsLinks{
    font-size: 20pt;
}

#app #mobileProjectDetails p{
    margin-bottom: 100px;
}

#app #mobileProjectDetails img{
    width: 80%;
}

  /* End of mobile Projects section */

  /* Beginning of mobile Education section */

@media screen and (max-width: 500px){
    #app #education #mobileEducation{
        font-size: 30pt !important;
    }
}

#app #education #mobileSchoolToggle{
    border: solid #000 1px;
    border-radius: 25px;
    display: inline-block;
    height: 50px;
    margin: 0 0 25px 0;
    width: 80%;
}
#app #education #mobileSchoolToggle p{
    display: inline-block;
    font-size: 15pt;
    padding: 12px 0 0 0;
    height: 38px;
    text-align: center;
    width: 50%;
}
#app #education #mobileSchoolToggle p:nth-of-type(1){
    border-radius: 25px 0 0 25px;
}
#app #education #mobileSchoolToggle p:nth-of-type(2){
    border-radius: 0 25px 25px 0;
}
#app #education #mobileSchoolToggle .schoolTypeInactive{
    animation: colorEmpty 500ms;
    background-color: rgba(160,160,160,.5);
}

  /* End of mobile Education section */

  /* Beginning of mobile Contact section */

@media screen and (max-width: 500px){
    #app #contact h1{
        font-size: 30pt !important;
    }
}

#app #contact #mobileContactForm{
    box-shadow: #808080 0px 0px 20px 1px;
    display: inline-block;
    margin: 20px 0 0 0;
    padding: 25px 0 0 0;
    width: 90%;
}
#app #contact #mobileContactForm input{
    background-color: #2c323b;
    border: none;
    color: #fffff0;
    display: inline-block;
    height: 50px;
    font-size: 15pt;
    margin: 0 0 30px 0;
    padding: 0 15px;
    width: 80%;
}
#app #contact #mobileContactForm input::placeholder{
    color: #fffff0;
}
#app #contact #mobileContactForm textarea{
    background-color: #2c323b;
    border: none;
    color: #fffff0;
    font-size: 15pt;
    min-height: 200px;
    padding: 3vw;
    width: 72vw;
}
#app #contact #mobileContactForm textarea::placeholder{
    color: #fffff0;
}
#app #contact #mobileContactForm button{
    background-color: #00b51b;
    border: none;
    border-radius: 20px;
    display: inline-block;
    font-size: 20pt;
    height: 50px;
    margin: 30px 30%;
    width: 150px;
    transition: all ease-in-out 200ms;
}
#app #contact #mobileContactForm button:hover{
    transform: scale(1.2);
}
#app #contact #mobileContactForm button #formIcon{
    display: inline-block;
    position: relative;
    right: 10px;
    top: 2px;
}

  /* End of mobile Contact section */